
import { defineComponent, ref } from 'vue'
import PageAuth from '@/components/layout/PageAuth.vue'
import TmFormLine from '@/components/shared/TmFormLine.vue'
import TmButton from '@/components/shared/TmButton.vue'
import { Form as Validation } from 'vee-validate'

export default defineComponent({
  components: {
    TmFormLine,
    TmButton,
    PageAuth,
    Validation,
  },
  setup() {
    const email = ref<string>('')

    return {
      email,
    }
  },
})
